<template>
    <div>
        <el-select style="width: 100%" :value="selectUserId" placeholder="请选择" @change="change">
            <el-option
                    clearable
                    v-for="item in users"
                    :key="item.id"
                    :label="item.id + ':' +item.name"
                    :value="item.id">
            </el-option>
        </el-select>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    export default {
        props:{
            role:{
                type:Number,
                default:5
            },
            selectUserId:{
                type:Number,
                default:0
            }
        },
        name: "selectUser",
        data() {
            return {
                users: [],
            }
        },
        watch:{
            selectUserId(v){
                console.log('------------------')
                console.log(v)
                this.selectUserId = v
            }
        },
        methods:{
            ...mapActions('user',['getUserList']),
            change(userId){
                this.$emit('onSelect',userId)
            }
        },
        mounted() {
            this.getUserList({role:this.role,pageSize:2000}).then(res => {
                if(res.ret === 0)
                    this.users = res.data.list
            })
        }
    }
</script>

<style scoped>

</style>